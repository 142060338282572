import React from 'react';
import * as queryString from "query-string";

const AgregarEntrenador = () => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) {
    return null;
  }
  
  const queryStringClassId = queryString.parse(window.location.search).id;
  window.location.replace(`rutina://client?addTrainer=${queryStringClassId}`)

  setTimeout("window.location = '/';", 1000);

  return null;
};

export default AgregarEntrenador;
